<template>
  <div>
    <loadingTela v-if="carregando"/>
    <v-card style="margin-top: 0; margin-bottom: 20px" >
    <!--<v-progress-linear :active="loadingTela" color="red" indeterminate  rounded  height="5"></v-progress-linear> -->
    <v-row>
      <v-col cols="12" xs="12" sm="12"  md="12" lg="12">
        <div style="text-align:center; margin-top: 10px">
          <v-img width="150" src="../assets/ambiente.png" style="margin: auto; margin-top: 20px; margin-bottom: 20px"></v-img>
        </div>
      </v-col>
    </v-row>
    <v-row class="pl-16 pr-16">
        <v-col cols="12" xs="12" sm="12"  md="5" lg="5">
          <v-form ref="form" v-model="isValid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
              v-model="nome"
              :rules="nameRules"
              label="Nome Completo"
              required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
              v-model="email"
              :rules="emailRules"
              label="Email"
              required
            ></v-text-field>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="12">
             <v-text-field
            v-model="whatsapp"
            :rules="whatsappRules"
            v-mask="'(##) #####-####'"
            label="WhatsApp"
            required
          ></v-text-field>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="12">
               <v-text-field
                v-model="cupom"
                label="Cupom"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
               <v-text-field
                v-model="usuario"
                :rules="usernameRules"
                label="Nome de Usuário"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
               <v-text-field
                v-model="senha"
                :rules="passwordRules"
                label="Senha"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
              :items="formasDePagamento"
              v-model="formaDePagamentoSelecionada"
              name="formadepagamento"
              item-text="Nome"
              :item-value="'Metodo'"
              label="Forma de pagamento"
            />
            </v-col>
          </v-row>
        </v-form>
        </v-col>
        <v-col cols="2">
        </v-col>
        <v-col cols="12" xs="12" sm="12"  md="5" lg="5"  style="text-align:center">
          <v-row>
            <v-col cols="12" xs="12" sm="12"  md="12" lg="12">
              <strong style=" color: grey">{{produto.Nome}}</strong>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="12"  md="12" lg="12">
              <v-img width="100" src="../assets/logo3.png" style="margin: auto; margin-top: 20px; margin-bottom: 20px"></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="12"  md="12" lg="12">
              <p style="margin: auto; margin-top: 10px; color: grey"  id="linhaValor"><strong>Valor: R$ {{MlvalorTotal}}</strong></p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="12"  md="12" lg="12" v-if="bCupom">
               <p style="margin: auto;font-size: 20px; color: grey" v-if="bCupom"><strong>Valor: R$ {{MlvalorCupom}}</strong></p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="12"  md="12" lg="12" v-if="bPix">
               <p style="margin: auto;font-size: 20px; color: grey" v-if="bPix"><strong>Valor: R$ {{MlvalorPix}}</strong></p>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="12" xs="12" sm="12"  md="12" lg="12" v-if="bTed">
               <p style="margin: auto;font-size: 20px; color: grey" v-if="bTed"><strong>Valor: R$ {{MlvalorTed}}</strong></p>
            </v-col>
          </v-row>
            <v-row>
            <v-col cols="12" xs="12" sm="12"  md="12" lg="12" v-if="bLabin">
               <p style="margin: auto;font-size: 20px; color: grey" v-if="bLabin"><strong>Valor: R$ {{MlvalorLabin}}</strong></p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="12"  md="12" lg="12">
               <small style="color: grey">{{mensagemDesconto}}</small>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="12"  md="12" lg="12">
              <v-btn @click="submitForm" text color="primary" dark>
                Ir Para Pagamento
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
    </v-row>
     <v-row>
      <v-col cols="12" xs="12" sm="12"  md="12" lg="12">
        <div style="text-align:center; margin-top: 10px">
          <v-img width="150" src="../assets/ambiente.png" style="margin: auto; margin-top: 20px; margin-bottom: 20px"></v-img>
        </div>
      </v-col>
    </v-row>

    </v-card>


<v-dialog v-model="dialogTed" max-width="450px" >
    <v-card style="padding: 30px !important; max-width: 100% !important">
      <v-card-text>
            <strong>Parabéns</strong></br>
            <p>Seus Dados foram enviados a nossa equipe, faça o depósito no valor de  <b>R${{MlvalorTed}}</b> via DOC/TED para: </p>
            <p><b>
            {{

                formasDePagamento.filter(x => x.Metodo == 'ted').length > 0 ? formasDePagamento.filter(x => x.Metodo == 'ted')[0].Descricao  : ''
            }}</b></p>
            <p>Após a transferência envie um email para: <b>{{contato.email}}</b>  com o comprovante de pagamento</p>
      </v-card-text>
  </v-card>
</v-dialog>

<v-dialog v-model="dialogIban" max-width="450px" >
    <v-card style="padding: 30px !important; max-width: 100% !important">
      <v-card-text>
            <strong>Parabéns</strong></br>
            <p>Seus Dados foram enviados a nossa equipe, faça o depósito no valor de:
            <p>USD: <b>{{BidDolar}}</b></p>
            <p>GBP: <b>{{BidLibra}}</b></p>
            <p><b>{{
              formasDePagamento.filter(x => x.Metodo == 'iban').length > 0  ? formasDePagamento.filter(x => x.Metodo == 'iban')[0].Descricao  : ''

               }}</b></p>
            <p>Após a transferência envie um email para: <b>{{contato.email}}</b> com o comprovante de pagamento</p>
      </v-card-text>
    </v-card>
    </v-dialog>
    <div>
      <stripe-checkout
        ref="checkoutRef"
        mode="payment"
        :pk="publishableKey"
        :line-items="lineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"

        @loading="v => loading = v"
      />
    </div>
    <!-- :locale="'es'" -->
    <!-- Snackbar for validation feedback -->
    <v-snackbar v-model="snackbar">
      {{ msgSnack }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Fechar</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import main from '../assets/scripts/main.js';
import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
  name: "Associando",
    components: {
     StripeCheckout,
      loadingTela: () => import("@/components/layout/loadingTela"),
  },
   mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://sdk.mercadopago.com/js/v2')
      document.head.appendChild(recaptchaScript);
    },
  data: () => ({
    isValid: false,
    snackbar: false,
    msgSnack: '',
    //dialogPix: false,
    dialogTed: false,
    carregando: false,
    dialogIban: false,
    publishableKey: 'pk_live_51JyN6nCwFMzJdR6uEvYxnypFwCOh6yUxgxCoZtGqfMVwzOuSpk58qak3fkxcQWMrzNSlqQjr7ymKTxKQ7QgEokyh00z75FKYy3',
    lineItems: [
      {
        price: '',
        quantity: 1,
      },
    ],
    contato: {
      email: '',
      whatsapp: ''
    },
    precoLegivel: '',
    successURL: window.location.origin + '/PagamentoRecebido',
    cancelURL: window.location.origin + '/Pagamento?tipo=',
    formaDePagamentoSelecionada: 'mercadopago',
    formasDePagamento: [
      {
        Metodo: 'ted',
        Descricao: ''
      },
    ],
    //loadingTela: true,
    nome: '',
    email: '',
    cupom: '',
    whatsapp: '',
    usuario: '',
    senha: '',

    nameRules: [
      v => !!v || 'Nome Completo é obrigatório',
      v => v.length >= 3 || 'O nome deve ter pelo menos 3 caracteres'
    ],
    emailRules: [
      v => !!v || 'Email é obrigatório',
      v => /.+@.+\..+/.test(v) || 'Email deve ser válido'
    ],
    whatsappRules: [
      v => !!v || 'WhatsApp é obrigatório',
      v => v.length === 15 || 'WhatsApp deve ser no formato (99) 99999-9999'
    ],
    usernameRules: [
      v => !!v || 'Nome de usuário é obrigatório',
      v => v.length >= 3 || 'Nome de usuário deve ter pelo menos 3 caracteres',
      v => !v.includes(" ") || 'O usuário não deve conter espaços',
    ],
    passwordRules: [
      v => !!v || 'Senha é obrigatória',
      v => v.length >= 6 || 'A senha deve ter pelo menos 6 caracteres',
      v => !v.includes(" ") || 'A senha não deve conter espaços',
    ],

    produto: {
      Descricao: ''
    },
    tipoProduto: null,
    valorReal: null,
    valorDesconto: null,
    valorFormaPagamento: null,
    valorCorrente: null,
    nomePatrocinio: '',
    valorFinal: null,
    cupomValido: false,
    descontoFormaValido: false,
    descontoAssociadoValido: false,
    globalid: null,

    MlvalorLabin: '',
    MlvalorTotal: '',
    MlvalorCupom: '',
    MlvalorPix: '',
    MlvalorTed: '',
    MlvalorIban: '',
    BidLibra: '',
    BidDolar: '',
    bCupom: false,

    bLabin: false,
    bPix: false,
    bTed: false,
    bIban: false,
    mensagemDesconto: '',
    cotacaoDolar: '',
    cotacaoLibra: '',

    desconto10: '',
    desconto50: '',
    desconto70: '',

    desconto10Valida: false,
    desconto50Valida: false,
    desconto70Valida: false,
  }),
  computed: {

  },
  watch: {
    async "cupom"(valor) {
      this.carregando = true;
      if(await this.verificaCupom()){
        this.bCupom = true;
        this.mensagemDesconto = 'Desconto por Cupom';
      }
      else{
        this.bCupom = false;
        this.mensagemDesconto = '';
      }
       this.carregando = false;
    },
    async "formaDePagamentoSelecionada"() {
      await this.verificaFormaPagamento();
    },
  },
  async created() {
    this.carregando = true;
    const params = new URLSearchParams(window.location.search)
    if(params.get('tipo') != null){
      this.tipoProduto = params.get('tipo');
    }
    await this.pegarFormasDePagamento();
    await this.pegarProdutoMercadoLivre();
    await this.pegarContatos();
    //await this.pegarCotacoes();
    //this.loadingTela = false
    this.carregando = false;
  },
  methods: {
    async pegarCotacoes(valorAtual){
      var respDolar = await main.getApiExterna({}, "https://economia.awesomeapi.com.br/json/last/USD-BRL");
      var retornoDolar = await respDolar.json();
      this.BidDolar = (valorAtual / retornoDolar.USDBRL.bid).toFixed(2);
      this.cotacaoDolar = retornoDolar.USDBRL.bid;

      var respLibra = await main.getApiExterna({}, "https://economia.awesomeapi.com.br/json/last/GBP-BRL");
      var retornoLibra = await respLibra.json();
      this.BidLibra = (valorAtual / retornoLibra.GBPBRL.bid).toFixed(2);
      this.cotacaoLibra = retornoLibra.GBPBRL.bid;
    },
    async verificaCupom(){
      if(this.cupom == this.desconto10 || this.cupom == this.desconto50 || this.cupom == this.desconto70 ){
          //this.cupomValido = true;
          var element = document.getElementById("linhaValor");
          element.classList.add("text-decoration-line-through");
          if(this.cupom == this.desconto10){
            this.bLabin = true;
            this.MlvalorLabin = ((this.MlvalorPix / 100) * 90).toFixed(2);
            this.desconto10Valida = true;
          }
          if(this.cupom == this.desconto50){
            this.bLabin = true;
            this.MlvalorLabin = ((this.MlvalorPix / 100) * 50).toFixed(2);
            this.desconto50Valida = true;
          }
          if(this.cupom == this.desconto70){
            this.bLabin = true;
            this.MlvalorLabin = ((this.MlvalorPix / 100) * 30).toFixed(2);
            this.desconto70Valida = true;
          }
      }
      else{
        var req = {
          cupom: this.cupom
        };
        var resp = await main.postApi(req, "autenticacao/getCordenadorCupom");
        var retorno = await resp.json();
        console.log(retorno)
        if(retorno.sucesso){
          var element = document.getElementById("linhaValor");
          this.cupomValido = true;
          this.MlvalorCupom =  ((this.MlvalorPix / 100) * (100 - retorno.corpo.Valor)).toFixed(2);
          this.nomePatrocinio = retorno.corpo.Nome + ' ' + retorno.corpo.Sobrenome
          element.classList.add("text-decoration-line-through");
        }
        else{
          var element = document.getElementById("linhaValor");
          this.cupomValido = false;
          this.MlvalorCupom = '';
          this.nomePatrocinio = '';
          element.classList.remove("text-decoration-line-through");
        }
      }


      return this.cupomValido;
    },
    async verificaFormaPagamento(){
      if(this.bCupom == false){
        if(this.formaDePagamentoSelecionada == 'mercadopago'){
          var element = document.getElementById("linhaValor");
          element.classList.remove("text-decoration-line-through");
          this.bCupom = false;
          //this.bPix = false;
          this.bTed = false;
          this.mensagemDesconto = '';
        }
        if(this.formaDePagamentoSelecionada == 'pix'){
            var element = document.getElementById("linhaValor");
            element.classList.add("text-decoration-line-through");
            this.bCupom = false;
            //this.bPix = true;
            this.bTed = false;
            this.mensagemDesconto = 'Desconto por PIX';
        }
        if(this.formaDePagamentoSelecionada == 'ted'){
            var element = document.getElementById("linhaValor");
            element.classList.add("text-decoration-line-through");
            this.bCupom = false;
            //this.bPix = false;
            this.bTed = true;
            this.mensagemDesconto = 'Desconto por TED/DOC';
        }
      }
    },
    async verificaAssociado(){

    },
    submitForm() {
      if (this.$refs.form.validate()) {
        // Perform the payment operation if form is valid
        this.pagar();
      } else {
        this.snackbar = true;
        this.msgSnack = 'Por favor, preencha todos os campos.';
      }
    },
    async pegarContatos(){
      var req = { setor: 'financeiro'}
      var resp = await main.postApi(req, "contatos/pegarContatos");
      var retorno = await resp.json();
      if(retorno.sucesso){
        this.contato.email = retorno.corpo.Email;
        this.contato.whatsapp = retorno.corpo.WhatsApp;
      }
    },
    async pegarFormasDePagamento(){
      var resp = await main.postApi({}, "admin/pegarFormasDePagamento");
      var retorno = await resp.json();
      if(retorno.sucesso){
        this.formasDePagamento = retorno.corpo;
      }
    },
    async pegarProduto(){
      var req = {
        produto: this.tipoProduto
      }
      var resp = await main.postApi(req, "stripe/pegarProduto");
      var retorno = await resp.json();

      if(retorno.sucesso){
        this.produto = retorno.corpo;
        this.valorReal = this.produto.Preco.filter(x => x.nickname == 'total')[0].valorDivido;
        this.valorDesconto = this.produto.Preco.filter(x => x.nickname == 'desconto')[0].valorDivido;
        this.valorFormaPagamento = this.produto.Preco.filter(x => x.nickname == 'formapagamento')[0].valorDivido;
        this.valorCorrente = this.valorReal;

      }
    },
    async pegarProdutoMercadoLivre(){
      var req = {
        modelo: this.tipoProduto
      }
      var resp = await main.postApi(req, "mercadopago/pegarProdutos");
      var retorno = await resp.json();

      if(retorno.sucesso){
          this.produto.Nome = retorno.corpo.Nome;
          this.MlvalorTotal = retorno.corpo.PrecoTotal;
          this.MlvalorCupom = retorno.corpo.PrecoCupom;
          this.MlvalorPix = retorno.corpo.PrecoPix;
          this.MlvalorTed = retorno.corpo.PrecoTed;
          this.desconto10 = retorno.corpo.Desconto10;
          this.desconto50 = retorno.corpo.Desconto50;
          this.desconto70 = retorno.corpo.Desconto70;
      }


    },
    async pagar(){
      this.carregando = true;
      localStorage.setItem(
        "user_account",
        JSON.stringify({
          nome: this.nome,
          tipoPerfil: 0,
          usuario: this.usuario,
          senha: this.senha,
          email: this.email,
          telefone: this.whatsapp,
        })
      )
      if(this.nome == '' || this.email == '' || this.whatsapp == '' || this.whatsapp == ''){
        this.snackbar = true
      }
      else{
            var valorFinal = this.bCupom ? this.MlvalorCupom : this.bPix ? this.MlvalorPix : this.bTed ? this.MlvalorTed : this.MlvalorTotal;
            if(this.desconto10Valida || this.desconto50Valida || this.desconto70Valida){
              valorFinal = this.MlvalorLabin
            }
            var description = `Nome: ${this.nome}, Email: ${this.email}, Origem: ${localStorage.getItem('origem') }, Cupom: ${this.cupom}, WhatsApp: ${this.whatsapp}`
            var req = {
              description: description,
              preco: valorFinal,
              quantity: '1',
              title: this.tipoProduto.toUpperCase(),
              email: this.email,
              //token: token
            };

            if(this.formaDePagamentoSelecionada == 'mercadopago'){
                var resp = await main.postApi(req, "mercadopago/create_preference");
                var retorno = await resp.json();
                window.location.assign(retorno.id.init_point);
            }
            if(this.formaDePagamentoSelecionada == 'ted'){
              //pegar localizacao
              var variavel = navigator.languages;
              linguagemAtual = '';
              if(variavel.length > 0){
                linguagemAtual = variavel[0]
              }
              //guardar dados
              var req = {
               nome:  this.nome,
               email: this.email,
               cupom: this.cupom,
               whatsapp: this.whatsapp,
               origem: localStorage.getItem('origem'),
               formadepagamento: 'ted',
               tipopagamento: 'transfer',
               valor: this.bCupom ? this.MlvalorCupom : this.bPix ? this.MlvalorPix : this.bTed ? this.MlvalorTed : this.MlvalorTotal,
               titulo: this.tipoProduto.toUpperCase(),
               localidade: linguagemAtual,
               data: new Date()
              };
              var resp = await main.postApi(req, "mercadopago/gravarPagamento");
              var retorno = await resp.json();
              if(retorno.sucesso){
                localStorage.setItem(
                  "user_account",
                  JSON.stringify({
                    nome: this.nome,
                    tipoPerfil: 0,
                    usuario: this.usuario,
                    senha: this.senha,
                    email: this.email,
                    telefone: this.whatsapp,
                  })
                )
              }
              this.dialogTed = true
            }
            if(this.formaDePagamentoSelecionada == 'iban'){
              //define valor
              await this.pegarCotacoes(this.bCupom ? this.MlvalorCupom : this.bPix ? this.MlvalorPix : this.bTed ? this.MlvalorTed : this.MlvalorTotal);
              //pegar localizacao
              var variavel = navigator.languages;
              var linguagemAtual = '';
              if(variavel.length > 0){
                linguagemAtual = variavel[0]
              }
              //guardar dados

              var req = {
               nome:  this.nome,
               email: this.email,
               cupom: this.cupom,
               whatsapp: this.whatsapp,
               origem: localStorage.getItem('origem'),
               formadepagamento: 'iban_transfer',
               tipopagamento: 'transfer',
               valor: this.bCupom ? this.MlvalorCupom : this.bPix ? this.MlvalorPix : this.bTed ? this.MlvalorTed : this.MlvalorTotal,
               titulo: this.tipoProduto.toUpperCase(),
               localidade: linguagemAtual,
               data: new Date(),
               cotacoes: `{CotacaoDolar: ${this.cotacaoDolar}, CotacaoLibra: ${this.cotacaoLibra}, ValorDolar: ${this.BidDolar}, ValorLibra: ${this.BidLibra} }`
              };
              var resp = await main.postApi(req, "mercadopago/gravarPagamento");
              var retorno = await resp.json();
              if(retorno.sucesso){
                localStorage.setItem(
                  "user_account",
                  JSON.stringify({
                    nome: this.nome,
                    tipoPerfil: 0,
                    usuario: this.usuario,
                    senha: this.senha,
                    email: this.email,
                    telefone: this.whatsapp,
                  })
                )
              }
              this.dialogIban = true
            }
        }
        this.carregando = false;
    },
  }
};
</script>
